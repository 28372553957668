var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "위험물 및 지정수량",
            tableId: "chemprod-inout",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            merge: _vm.grid.merge,
            gridHeight: "700px",
            selection: "multiple",
            rowKey: "chmDangerItemId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "선택", icon: "check" },
                    on: { btnClicked: _vm.select },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }