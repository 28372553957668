<template>
  <div>
    <c-table
      ref="table"
      title="위험물 및 지정수량"
      tableId="chemprod-inout"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      gridHeight="700px"
      selection="multiple"
      rowKey="chmDangerItemId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'danger',
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
      },
      grid: {
        merge: [
          { index: 0, colName: 'regulatedItemCd' },
          { index: 1, colName: 'regulatedItemCd' },
        ],
        columns: [
          {
            name: 'danger',
            field: 'danger',
            label: '위험물',
            child: [
              {
                name: 'regulatedItemName',
                field: 'regulatedItemName',
                label: '유별',
                align: 'center',
                sortable: true,
                style: 'width:100px',
              },
              {
                name: 'regulationDesc',
                field: 'regulationDesc',
                label: '성질',
                align: 'center',
                sortable: true,
                style: 'width:100px',
              },
              {
                name: 'dangerItemName',
                field: 'dangerItemName',
                label: '품명',
                align: 'left',
                sortable: true,
                style: 'width:200px',
              },
            ]
          },
          {
            name: 'designatedQuantity',
            field: 'designatedQuantity',
            label: '지정수량',
            align: 'center',
            sortable: true,
            style: 'width:150px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.base.dangerItem.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
